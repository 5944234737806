import React from "react"
import { Waypoint } from 'react-waypoint';

import ContactForm from  "./ContactForm"

import {handleWaypointIn, handleWaypointOut} from "../../utilities/waypoint"


const ContactSection = () => { 

    return (
        <section className="contact-me bg-pan-left" id="get-in-touch">
            <div className="all-wrapper">
                <Waypoint 
                    onEnter={() => handleWaypointIn('contact-h2')}
                    onLeave={() => handleWaypointOut('contact-h2')}
                >            
                    <h2 className="scale0" id="contact-h2">Get in touch</h2> 
                </Waypoint>
                <ContactForm />       
            </div>          
        </section>  
    )
}  
  

export default ContactSection



